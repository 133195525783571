<template>
  <div class="container">
    <div class="bg_wra">
      <div class="title">全场景管控和精细化定制</div>
      <div class="subtitle">深度满足企业数字化经营需求</div>
      <a-button type="primary" shape="round" size="large" class="expirence">
        <router-link :to="{path:'/logindex'}" >立即体验</router-link>
        </a-button>
    </div>
    <div class="tab_con">
      <a-row type="flex" justify="space-around">
        <a-col :class="[{active:current==1},'tab_items']" @click="current=1"><img src="../assets/image/product/tab_1.png" alt=""> 智慧社区云管理系统</a-col>
        <a-col :class="[{active:current==2},'tab_items']" @click="current=2"><img src="../assets/image/product/tab_4.png" alt=""> 物联网管理系统</a-col>
        <a-col :class="[{active:current==3},'tab_items']" @click="current=3"><img src="../assets/image/product/tab_2.png" alt=""> 住户端APP/小程序</a-col>
        <a-col :class="[{active:current==4},'tab_items']" @click="current=4"><img src="../assets/image/product/tab_3.png" alt=""> 七彩芯员工端APP/小程序</a-col>
      </a-row>
    </div>
    <div class="content">
      <div v-if="current==1">
        <div class="title">智慧社区云管理系统</div>
        <div class="subtitle">为您筑造智能化社区管理方式</div>
        <img src="../assets/image/product/pms_1.png" width="730" alt="" style="margin: 25px 0;">
        <div class="title">平台核心功能</div>
        <a-row type="flex" align="middle" style="margin: 20px 0 70px;">
          <a-col :flex="2" style="text-align: left"><img src="../assets/image/product/pms_2.png" width="688" alt=""></a-col>
          <a-col :flex="1" class="func_list">
            <ul class="tab_ul1">
              <li class="tab_li1">工单管理</li>
              <li class="tab_li1">品质管理</li>
              <li class="tab_li1">设备管理</li>
              <li class="tab_li1">收费管理</li>
              <li class="tab_li1">票证管理</li>
              <li class="tab_li1">费用报表</li>
              <li class="tab_li1">仓库管理</li>
              <li class="tab_li1">电车充电</li>
              <li class="tab_li1">资产管理</li>
              <li class="tab_li1">客户管理</li>
            </ul>
          </a-col>
        </a-row>
        <div class="title">系统优势与亮点</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">一平台统管所有社区工作</div>
            <div class="info_s">社区用户与员工同一平台管理，社区运营服务与相关费用收取全线上化进行。深度专业闭环社区费用收取、社区客服报事报修工单、设备设施给养等社区业务全流程。</div>
          </a-col>
          <a-col><img src="../assets/image/product/pms_3.png" width="670" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/pms_4.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">智慧云账务</div>
            <div class="info_s">全场景线上线下自动处理账单、收费、发票、凭证、统计全过程，实现账、钱、证一体化运行，打造差异化社区收费全体验。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">智慧客服</div>
            <div class="info_s">集成400呼叫中心与HR系统，提供完善的工单系统，通过客户标签、房间数据统一化管理，实现实时掌握客户动向，客诉处理一目了然。</div>
          </a-col>
          <a-col><img src="../assets/image/product/pms_5.png" class="img_s" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/pms_6.png" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">智慧结算</div>
            <div class="info_s">支持全场景、多渠道支付模式，实时、自动、多级分账。实现对各业务系统资金账户的统一运营和管控。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">智慧仓库</div>
            <div class="info_s">针对社区物料零散痛点，提供出入库管理、库存盘点调拨管理，仓库报表实时动态查询。</div>
          </a-col>
          <a-col><img src="../assets/image/product/pms_7.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/pms_8.png" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">智慧设备</div>
            <div class="info_s">社区所有设备设施的维修、保养、巡检、工单一体化管理，多端查看与使用。</div>
          </a-col>
        </a-row>
      </div>
      <div v-if="current==2">
        <div class="title">物联网管理系统</div>
        <div class="subtitle">通过AIoT连接人、设备、空间和服务，实现空间数字化</div>
        <img src="../assets/image/product/wulian4-1.png" width="730" alt="" style="margin: 25px 0;">
        <!--<img src="../assets/image/product/guanwang_1.png" alt="" width="680" style="margin: 20px 0;">-->
        <div class="title">平台核心功能</div>
        <a-row type="flex" align="middle" style="margin: 20px 0 70px;">
          <a-col :flex="2" style="text-align: left"><img src="../assets/image/product/wulian4-2.png" width="688" alt=""></a-col>
          <a-col :flex="1" class="func_list">
            <ul class="tab_ul4">
              <li class="tab_li4">门禁管理</li>
              <li class="tab_li4">停车管理</li>
              <li class="tab_li4">水电表管理</li>
              <li class="tab_li4">公配电管理</li>
              <li class="tab_li4">消防管理</li>
              <li class="tab_li4">供水管理</li>
              <li class="tab_li4">电梯管理</li>
              <li class="tab_li4">视频管理</li>
            </ul>
          </a-col>
        </a-row>
        <div class="title">系统优势与亮点</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">智能人行系统</div>
            <div class="info_s">高效通行——人脸/扫码/卡片/蓝牙/远程，多种开门方式，满足不同场景通行需求；访客管理——访客扫码通行，无需登记，简单方便；通行记录云端存储，有迹可循，安全可控；数据管理——业主、访客、租户、物管、商家服务人员等不同人员类型的数据线上管理。</div>
          </a-col>
          <a-col><img src="../assets/image/product/wulian4-3.png" width="670" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/wulian4-4.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">智能EBA系统</div>
            <div class="info_s">实时监测：电压柜、水泵房、设备房、电梯等设备联网，设备数据实时上传，随时可查；远程监控：连接视频监控，实时查看设备房监控画面；逻辑告警：关联告警类型，基于智能分析、逻辑判断，推送告警信息。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">智能车行系统</div>
            <div class="info_s">智慧通行：AI识别车牌，智能算法，识别率>99.5%；智能支付：多样化缴费方式，收费实时到达指定账户；远程对讲：双向对讲，出入口自主通行。</div>
          </a-col>
          <a-col><img src="../assets/image/product/wulian4-5.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/wulian4-6.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">智能视频监控系统</div>
            <div class="info_s">实时检查：远程诊断现场品质，扩大管理半径，提高管理效率；快速报事：发现问题及时报事，快速整改保障品质；任务推送：定时定点图片抓取，切片检查供方考核。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">智能水电表系统</div>
            <div class="info_s">远程自动抄表，可配置抄表频度，高计量精度，数据可系统调取查看，离线缓存数据。</div>
          </a-col>
          <a-col><img src="../assets/image/product/wulian4-7.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/wulian4-8.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">设备工单系统</div>
            <div class="info_s">利用工单进行业务闭环，实现流程标准化，让品质状况自动分析，检核结果考核供方。</div>
          </a-col>
        </a-row>
        <!--<a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">自定义域名 提升品牌形象</div>
            <div class="info_s">支持域名自定义链接，打造独立的品牌形象</div>
          </a-col>
          <a-col><img src="../assets/image/product/guanwang_2.png" width="690" alt=""></a-col>
        </a-row>-->
      </div>
      <div v-if="current==3">
        <div class="title">住户端APP/小程序</div>
        <div class="subtitle">一个APP 畅享社区所有服务</div>
        <img src="../assets/image/product/zhu2-1.png" width="730" alt="" style="margin: 25px 0;">
        <div class="title">平台核心功能</div>
        <a-row type="flex" align="middle" style="margin: 20px 0 70px;">
          <a-col :flex="2" style="text-align: left"><img src="../assets/image/product/zhu2-2.png" width="688" alt=""></a-col>
          <a-col :flex="1" class="func_list">
            <ul class="tab_ul2">
              <li class="tab_li2">手机开门</li>
              <li class="tab_li2">访客管理</li>
              <li class="tab_li2">生活缴费</li>
              <li class="tab_li2">扫码充电</li>
              <li class="tab_li2">电梯预约</li>
              <li class="tab_li2">社区商城</li>
              <li class="tab_li2">活动投票</li>
              <li class="tab_li2">报事报修</li>
              <li class="tab_li2">投诉建议</li>
              <li class="tab_li2">社区服务</li>
            </ul>
          </a-col>
        </a-row>
        <div class="title">系统优势与亮点</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">住户通行</div>
            <div class="info_s">支持人脸、二维码、远程、蓝牙开门，对接主流智能设备厂商，后台将信息全部记录，有迹可循的同时，拒绝不安全因素。</div>
          </a-col>
          <a-col><img src="../assets/image/product/zhu2-3.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/zhu2-4.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">访客通行</div>
            <div class="info_s">添加访客信息，无需门卫登记，访客使用二维码开门，或驾车来访时车牌自动识别访客，增效省力又安全。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">生活缴费</div>
            <div class="info_s">水费、电费、物业费、车位费，每笔缴费记录清晰记录，缴费账单轻松查询，可在线申请开具电子发票。</div>
          </a-col>
          <a-col><img src="../assets/image/product/zhu2-5.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/zhu2-6.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">报事报修</div>
            <div class="info_s">公区和户内均可拍照后直接发起工单，物业人员及接单时间、处理时长在线显示，及时解决住户维修诉求。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">扫码充电</div>
            <div class="info_s">固定时长充电+智能充电，充满即停，安全又节能。</div>
          </a-col>
          <a-col><img src="../assets/image/product/zhu2-7.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/zhu2-8.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">电梯预约</div>
            <div class="info_s">轻松一键，预约电梯 节省您的每分每秒。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">社区商城</div>
            <div class="info_s">让居家生活更方便。小管家送货上门，随时享受贴心的生活管家，购物与服务一键搞定。</div>
          </a-col>
          <a-col><img src="../assets/image/product/zhu2-9.png" width="663" alt=""></a-col>
        </a-row>
      </div>
      <div v-if="current==4">
        <div class="title">七彩芯员工端APP/小程序</div>
        <div class="subtitle">一站式运营服务管理平台，为员工提供赋能工具，提升工作效率，让运营管理更便捷、更高效</div>
        <img src="../assets/image/product/yunxing3-1.png" width="730" alt="" style="margin: 25px 0;">
        <div class="title">平台核心功能</div>
        <a-row type="flex" align="middle" style="margin: 20px 0 70px;">
          <a-col :flex="2" style="text-align: left"><img src="../assets/image/product/yunxing3-2.png" width="688" alt=""></a-col>
          <a-col :flex="1" class="func_list">
            <ul class="tab_ul3">
              <li class="tab_li3">工单管理</li>
              <li class="tab_li3">工作管理</li>
              <li class="tab_li3">设备管理</li>
              <li class="tab_li3">经营管理</li>
              <li class="tab_li3">在线催缴</li>
              <li class="tab_li3">公区报修</li>
              <li class="tab_li3">品质巡查</li>
              <li class="tab_li3">学习中心</li>
            </ul>
          </a-col>
        </a-row>
        <div class="title">系统优势与亮点</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">智能工单</div>
            <div class="info_s">工单按照岗位、闲忙情况实现自动派发，形成标准化的工单流转分配体系。</div>
          </a-col>
          <a-col><img src="../assets/image/product/yunxing3-3.png" width="670" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/yunxing3-4.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">工作管理</div>
            <div class="info_s">定时派发周期性工作任务定时提醒，工作执行情况有迹可循。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">学习中心</div>
            <div class="info_s">随时随地轻松学习。</div>
          </a-col>
          <a-col><img src="../assets/image/product/yunxing3-5.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/yunxing3-6.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">设备管理</div>
            <div class="info_s">一码一设备，查看设备台账、维养、维修记录。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">经营管理</div>
            <div class="info_s">为物业公司高管、分公司总经理、项目经理、管家提供多级次欠费数据和收缴率数据，为公司做经营支撑。</div>
          </a-col>
          <a-col><img src="../assets/image/product/yunxing3-7.png" width="663" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/yunxing3-8.png" class="img_s" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">在线催缴</div>
            <div class="info_s">管家可根据自己管理的楼栋范围查看每个房间的信息，方便快速查询实收、未收、欠收费用账单，并支持在线或上门催缴。</div>
          </a-col>
        </a-row>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ProductService",
  data(){
    return{
      current:1
    }
  },
  methods:{
    /*expirence(){
      window.open('http://www.9zhinet.com/main/ys')
    }*/
  },
  created() {
    if(this.$route.params.key){
      this.current=this.$route.params.key
    }
  }
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  text-align: center;
  color: #fff;
  background: url("../assets/image/bg_product.png") no-repeat center;
  background-size: cover;
  .title{
    font-size: 60px;
    padding-top: 120px;
  }
  .subtitle{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .expirence{
    width: 180px;
  }
}
.tab_con{
  width: 1200px;
  height: 120px;
  background-color: #fff;
  margin: -60px auto 0;
  box-shadow: 0px 7px 12px 4px rgba(225, 225, 225, 0.5);
  border-radius: 8px;
  .tab_items{
    height: 118px;
    line-height: 118px;
    font-size: 20px;
    border-bottom: solid 2px transparent;
    cursor: pointer;
    &.active{
      border-bottom-color: #0167FF;
    }
    &:hover{
      border-bottom-color: #0167FF;
    }
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  .title{
    font-size: 32px;
    margin-bottom: 20px;
  }
  .subtitle{
    font-size: 16px;
  }
  .info_l{
    max-width: 426px;
    text-align: left;
    font-size: 28px;
  }
  .info_s{
    max-width: 426px;
    text-align: left;
    font-size: 16px;
    color: #666;
    margin-top: 10px;
  }
  .col-item{
    width: 368px;
    height: 260px;
    box-shadow: 0px 5px 20px 9px #F3F3F3;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      margin-bottom: 30px;
    }
    p{
      font-size: 20px;
      color: #666;
      margin: 0;
    }
  }
  ul{
    padding: 70px 0 0;
    overflow: hidden;
    width: 400px;
    .tab_li1{
      &:before{
        background-image: url("../assets/image/jingling.png");
      }
    }
    .tab_li2{
      &:before{
        background-image: url("../assets/image/jingling02.png");
      }
    }
    .tab_li3{
      &:before{
        background-image: url("../assets/image/jingling03.png");
      }
    }
    .tab_li4{
      &:before{
        background-image: url("../assets/image/jingling04.png");
      }
    }
    li{
      list-style: none;
      float: left;
      width: 50%;
      text-align: left;
      padding-left: 27px;
      margin-top: 50px;
      font-size: 16px;
      &:before{
        display: inline-block;
        content: "";
        width: 30px;
        height: 30px;
        background-image: url("../assets/image/jingling.png");
        vertical-align: middle;
        margin-right: 20px;
      }
      &:nth-child(1):before{
        background-position: 0px 0px;
      }
      &:nth-child(2):before{
        background-position: -134px 0px;
      }
      &:nth-child(3):before{
        background-position: 0px -82px;
      }
      &:nth-child(4):before{
        background-position: -134px -81px;
      }
      &:nth-child(5):before{
        background-position: -1px -163px;
      }
      &:nth-child(6):before{
        background-position: -134px -162px;
      }
      &:nth-child(7):before{
        background-position: -1px -243px;
      }
      &:nth-child(8):before{
        background-position: -134px -242px;
      }
      &:nth-child(9):before{
        background-position: -1px -323px;
      }
      &:nth-child(10):before{
        background-position: -134px -322px;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .bg_wra{
    height: 300px;
    background-position: top;
    .title{
      font-size: 28px;
    }
    .subtitle{
      font-size: 18px;
    }
    .expirence{
      display: none;
    }
  }
  .tab_con{
    width: 100%;
    height: 60px;
    margin-top: -30px;
    .tab_items{
      font-size: 12px;
      height: 58px;
      line-height: 58px;
      img{
        height: 16px;
      }
    }
  }
  .content{
    width: 100%;
    padding: 40px 25px;
    .title{
      font-size: 22px;
    }
    .info_l{
      max-width: 100%;
      font-size: 20px;
      text-align: center;
    }
    .info_s{
      max-width: 100%;
      font-size: 14px;
    }
    .order{
      order: -1;
    }
    img{
      width: 100%;
    }
    .img_s{
      width: 80%;
      margin-top: 30px;
    }
    .img_s2{
      width: 90%;
      margin: 30px 5% 0;
    }
    .func_list{
      height: 380px;
    }
    ul{
      padding-top: 0;
      width: 100%;
      transform: scale(.9);
    }
    .col-item{
      width: 33.3%;
      p{
        font-size: 14px;
      }
      img{
        width: 50px;
      }
    }
  }
}
</style>
